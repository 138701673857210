@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";

.container {
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 64rem;
    min-height: 100vh;
}

.container-main {
    display: flex;
    /*padding-left: 1rem;*/
    /*padding-right: 1rem;*/
    flex-direction: column;
    flex: 1 1 0;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 250px;
}

.content-header {
    display: flex;
    margin-top: 2.5rem;
    /*margin-left: 0.75rem;*/
    /*margin-left: 0.875rem;*/
    align-items: center;
}

.content-header p {
    font-weight: 500;
    text-align: left;
}

.loading {
    display: inline-flex;
    align-items: center;
}

.loading .spacer {
    margin-right: 2px;
}

.loading span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 1px;
}

.loading span:nth-of-type(2) {
    animation-delay: 0.2s;
}

.loading span:nth-of-type(3) {
    animation-delay: 0.4s;
}

.textarea-with-arrow::before {
    content: '←';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

.content-input {
    margin-bottom: auto;
    list-style: disc;
    /*margin-left: 10px;*/
    font-size: 25px;
    display: flex;
    align-items: center;
}

.content-input input {
    background-color: black;
    border: none;
    color: white;
    font-size: 20px;
}

.content-input input:focus {
    background-color: black;
    border: none;
    box-shadow: none;
    color: white;
}

.content-input .arrow--closed {
    color: #808080;
}

.data-filled .arrow--closed {
    color: white;
}

.content-input .arrow--open {
    transform: rotate(90deg);
}

.submit-btn {
    display: none;
}

.loader-btn {
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    border-radius: 0.75rem;
    width: 100%;
    font-weight: 500;
    color: #000000;
    background-color: #000000;
    border: none;
}

.tree-view {
    font-size: 16px;
    user-select: none;
    min-height: 320px;
    padding: 0 20px 20px 40px;
    box-sizing: border-box;
    margin-top: 0.5rem;
    width: 100%;
    max-width: 36rem;
    /*margin-left: 10px;*/
}

.tree-view .tree,
.tree-view .tree-node,
.tree-view .tree-node-group {
    list-style: none;
    margin: 0;
    padding: 0;
}

.tree-view .tree-branch-wrapper,
.tree-view .tree-node__leaf {
    outline: none;
}

.tree-view .tree-node {
    cursor: pointer;
}

.tree-view .tree-node .name span,
.tree-view .tree-node .no-element span {
    font-weight: bold;
}

.tree-view .tree-node .name {
    margin-left: 10px;
    margin-bottom: 5px;
}

.more-button {
    color: blue;
    margin-left: 10px;
    text-decoration: underline;
}

.more-button:hover {
    font-weight: bold;
}

.tree-view .tree-node .no-element {
    margin-left: 35px;
    white-space: pre-line;
    margin-bottom: 5px;
}

.tree-view .tree-node {
    display: flex;
}

.tree-view .arrow {
    margin-left: 5px;
    vertical-align: middle;
    font-size: 25px;
}

.tree-view .content {
    display: inline-block;
}

.tree-view .arrow--open {
    transform: rotate(90deg);
}

.tree-view .tree li .content {
    min-width: 576px;
    max-width: 576px;
    padding-right: 10px;
}

.search-header-form {
    max-width: 636px;
    width: 100%;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-icon {
    animation: spinner 1.5s linear infinite;
    margin-left: 15px;
    vertical-align: middle;
    font-size: 15px;
}

.visually-hidden {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
}

.breadcrumb {
    margin: 0 auto;
    padding: 5px 15px;
    background-color: #333333;
    position: sticky;
    top: 0;
    z-index: 9999;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: '>';
    color: #ffffff;
    pointer-events: none;
}

.breadcrumb-item {
    cursor: pointer;
}

.breadcrumb-item:last-child {
    text-decoration: underline;
    pointer-events: none;
}

@media (max-width: 576px) {
    .tree-view .tree li .tree-node {
        min-width: 100vw;
        padding-right: 15px;
    }
}

.tooltip-container {
    position: absolute;
    display: inline-block;
}

.tooltip-content {
    display: none;
    position: relative;
    background-color: #333;
    color: #fff;
    padding: 3px;
    border-radius: 5px;
    z-index: 1;
    top: -23px;
    right: -17%;
    font-size: 12px;
    transform: translateX(0);
    margin-left: 5px;
}

.tooltip-container:hover .tooltip-content {
    display: block;
}

.tools-options {
    display: none;
}


@media (max-width: 575px) {
    .tooltip-container:hover .tooltip-content {
        display: none;
    }
}

@media (min-width: 1024px) {
    .tree-view .tree .content:hover > .tools-options {
        display: inline;
    }
}

@media (max-width: 1024px) {
    .tools-options.used {
        display: inline;
    }
}

.tools-content {
    margin-left: 5px;
}

.tools-content .tools-name {
    text-decoration: underline;
    cursor: pointer;
}


.main-body {
    display: flex;
}

.sidebar {
    position: fixed;
    background-color: #383838;
}
.sidebar .close-icon {
    cursor: pointer;
    font-size: 22px;
    display: flex;
    justify-content: flex-end;
    transition-duration: 15s;
    position: absolute;
    padding: 10px;
    top: 10px;
    left: 10px;
    border: 1px solid gray;
    border-radius: 0.375rem;
}

.sidebar .show-icon {
    cursor: pointer;
    font-size: 22px;
    display: flex;
    justify-content: flex-end;
    transition-duration: 15s;
    position: absolute;
    padding: 10px;
    right: 10px;
    z-index: 1;
    border: 1px solid gray;
    border-radius: 0.375rem;
}

.sidebar.hide-sidebar + .container-main {
    padding-left: 0;
}

.sidebar-chat {
    display: flex;
    flex-direction: row;
    padding: 10px 0 0 10px;
}

.hide-chat {
    background: none;
}

.sidebar-chat .new-chat {
    border: 1px solid gray;
    border-radius: 0.375rem;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    padding: 0.60rem 0.75rem;
    align-items: center;
    flex-grow: 0.5;
    gap: 0.75rem;
}

.sidebar.hide-sidebar .sidebar-list {
    width: 0;
    visibility: hidden;
}

.sidebar-list {
    position: relative;
    padding-top: 15px;
    width: 250px;
}

.sidebar-scroll {
    padding: 0 5px 10px 10px;
    height: 85vh;
    overflow: auto;
}

.footer .user-detail {
    height: 50px;
    display: flex;
    justify-content: start;
    position: relative;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    gap: 10px;
    border-radius: 5px;
    transition: all 0.3s;
    color: #ffffff;
    text-decoration: none;
}

.footer {
    padding: 0px 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.18);
}

.footer .user-detail:hover {
    background-color: #000000;
}

.sidebar-scroll::-webkit-scrollbar {
    width: 5px;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 5px;
}

.sidebar .title {
    font-size: 12px;
    line-height: 1;
    padding: 12px 12px 5px;
    color: #8f8f8f;
}

.sidebar .search-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar .search-list .search-name {
    overflow: hidden;
    position: relative;
    flex: 1 1 0%;
    max-height: 1.25rem;
    text-overflow: ellipsis;
    word-break: break-all;
}

.sidebar .search-list li div {
    overflow: hidden;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    border-radius: 5px;
    transition: all 0.3s;
    color: #ffffff;
    text-decoration: none;
}

.sidebar .search-list li div:hover {
    background-color: #000000;
}

.sidebar .search-list li div .search-name {
    font-size: 14px;
}

.sidebar .search-list li div .search-name .list-shadow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 32px;
    z-index: 10;
    background-image: linear-gradient(to left, #383838, rgba(56, 56, 56, 0));
}

.sidebar .search-list li div:hover .search-name .list-shadow {
    background-image: linear-gradient(to left, #000000, rgba(0, 0, 0, 0));;
}

.content.Black {
    background-color: #000000;
    color: #ffffff;
}

.content.Purple {
    background-color: #4B0082;
    color: #E6E6FA ;
}

.content.Red{
    background-color: #8B0000;
    color: #F5F5DC ;
}

.content.Yellow{
    background-color: #FFD700;
    color: #8B4513 ;
}

.content.Orange {
    background-color: #FFA500;
    color: #000080 ;
}
