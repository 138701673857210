.login {
    display: flex;
    height: 100vh;
}

.login .content-main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    background-color: #ffffff24;
    width: 100%;
}

.login .content-main h3 {
    font-size: 30px;
    margin-bottom: 20px;
}

.content-main .login-details {
    text-align: center;
}

.content-main .login-details p {
    font-size: 18px;
}

.content-main .login-details button {
    padding: 0.5rem 1rem;
    margin-bottom: 5px;
    border-radius: 0.75rem;
    font-weight: 500;
    color: #ffffff;
    background-color: rgb(60, 70, 255);
    border: none;
}
